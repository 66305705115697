<template>
  <b-modal v-model="show" title="Remove From Private User" hide-footer @hide="onHide">
    <el-descriptions class="margin-top mb-3" title="" :column="1" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          Name
        </template>
        {{ selectedUser.full_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-message"></i>
          Email
        </template>
        {{ selectedUser.email }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-phone"></i>
          Phone Number
        </template>
        {{ selectedUser.phone_number }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          Company
        </template>
        {{ selectedUser.company_name }}
      </el-descriptions-item>
    </el-descriptions>
    <p class="mt-2 mb-2">Are you sure want to remove <code>{{ selectedUser.full_name }}</code> from <code>{{ selectedUser.company_name }}</code> private user?</p>
    <div class="d-flex float-right">
      <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Yes</el-button>
      <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
    </div>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
// import { required } from '@vuelidate/validators';
import { GET_COMPANIES } from '@/store/modules/companies';
import { SINGLE_REMOVE_PRIVATE_USERS } from '@/store/modules/app_user';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'SingleRemovePrivate',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        // company_id: { required },
      },
    };
  },
  data() {
    return {
      show: false,
      loading: {
        company: false,
        submit: false,
      },
      form: {
        company_id: '',
      },
      companies: {
        rows: [],
        count: 0,
      },
      timeoutSearchCompany: null,
    };
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.loading.company = false;
      this.companies = this.$store.getters.companies;
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    onSubmit() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      this.$confirm(`Are you sure want remove ${this.selectedUser.full_name} as a private for ${this.selectedUser.company_name}?`, 'Confirmation', {
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            this.submitUser();
          }
          this.onHide();
          done();
        },
      }).catch(() => {});
    },
    async submitUser() {
      this.loading.submit = true;
      await this.$store.dispatch(SINGLE_REMOVE_PRIVATE_USERS, {
        id: this.selectedUser.company_id,
        data: {
          company_id: this.selectedUser.company_id,
          owned_by: this.selectedUser.owned_by,
        },
      }).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Successfully remove user as private',
        });
        this.$parent.getList();
        this.form = {
          company_id: '',
        };
        this.onHide();
      }).catch((err) => {
        if (err.response) {
          popupErrorMessages(err.response);
        }
      });
      this.loading.submit = false;
    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        this.loadCompanies();
      }
    },
  },
};
</script>