<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card">
      <b-tabs @activate-tab="handlerTabChange" :value="current_active_tab">
        <b-tab title="Private User List">
          <div class="card-header border-0 justify-content-between">
            <div class="card-title">
              <span class="mr-2">Private User List</span>
            </div>
            <div class="flex flex-row mb-2 pr-2 float-right">
              <b-input style="width: 200px" size="sm" type="text" v-model="search_keyword" placeholder="Input search keyword" class="mr-2"/>
              <el-select size="small" v-model="search_by" class="mr-2" style="width: 150px">
                <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
              </el-select>
              <el-button type="info" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
              <el-button  @click="doDownload" effect="dark" type="primary" class="ml-2" size="mini">Download</el-button>
              <el-button :disabled="!canImportUser" @click="doShowModalImportUser" effect="dark" type="primary" class="ml-2" size="mini"><font-awesome-icon icon="user-plus"/>&nbsp;Import private user</el-button>
              <el-button :disabled="!canImportUser" @click="doShowModalRemoveUser" effect="dark" type="danger" class="ml-2" size="mini"><font-awesome-icon icon="user-times"/>&nbsp;Remove private user</el-button>
              <el-button :disabled="!canImportUser" @click="doShowModalEwaTier" effect="dark" type="primary" class="ml-2" size="mini">&nbsp;Ewa Tier</el-button>
            </div>
          </div>
          <div class="card-table table-responsive">
           <el-table :data="list"
              ref="list_table"
              @selection-change="handlerSelectionChange"
             v-loading="loading.list"
              class="table card-table text-nowrap table-vcenter"
              style="width: 100%">
              <el-table-column type="selection" width="65"/>
              <el-table-column width="200" prop="full_name" label="Name" :sortable="true"/>
              <el-table-column min-width="250" prop="email" label="Email"></el-table-column>
              <el-table-column width="150" prop="phone_number" label="Phone Number"></el-table-column>
              <el-table-column width="150" prop="ewa_tier" label="Ewa Tier"></el-table-column>
              <el-table-column width="150" :formatter="(row) => getEwaDetails(row.ewa_tier).admin_ewa" label="Admin Ewa"></el-table-column>
              <el-table-column width="150" :formatter="(row) => getEwaDetails(row.ewa_tier).req_ewa_limit" label="Ewa Req Limit"></el-table-column>
              <el-table-column width="150" prop="created_at_str" label="Join Date"></el-table-column>
              <el-table-column width="150" prop="login_at_str" label="Last Login Date"></el-table-column>
              <el-table-column width="150" prop="status" label="Status">
                <template slot-scope="item">
                  <span class="tag" :class="item.row.status_color">{{ item.row.status_str }}</span>
                </template>
              </el-table-column>
              <template slot="empty">
                <el-empty description="No data found" :image="emptyStateImage"></el-empty>
              </template>
            </el-table>
          </div>
          <el-empty v-if="total_rows == 0" description="No data found" :image="emptyStateImage"></el-empty>
          <pagination
            :page="page"
            :per_page="per_page"
            :list="list"
            :total_rows="total_rows"
            @pageChange="pageChangeHandler"/>
        </b-tab>
        <b-tab title="Private User Draft">
          <div class="card-header border-0 justify-content-between">
            <div class="card-title">
              <span class="mr-2">Private User Draft</span>
            </div>
            <div class="flex flex-row mb-2 pr-2 float-right">
              <el-input clearable @clear="searchHandlerDraft" style="width: 200px" size="small" type="text" v-model="draft_opt.search.search_keyword" placeholder="Input search keyword" class="mr-2"/>
              <el-select size="small" v-model="draft_opt.search.search_by" class="mr-2" style="width: 150px">
                <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
              </el-select>
              <el-button type="primary" @click="searchHandlerDraft" size="small"><font-awesome-icon icon="search"/> Search</el-button>
            </div>
          </div>
          <div class="table-responsive">  
            <table class="table card-table text-nowrap table-vcenter">
              <thead>
                <tr>
                  <th width="20%">Name</th>
                  <th width="20%">Email</th>
                  <th width="10%">Phone Number</th>
                  <th>Upload Date</th>
                  <th>Uploader Name</th>
                  <th>Status</th>
                  <th>Checker Name</th>
                  <th v-if="canApproveUser">#</th>
                </tr>
              </thead>
              <tbody v-loading="loading.list">
                <tr v-for="(item, i) in draft_opt.list" :key="i">
                  <td>{{ item.full_name }}</td>
                  <td>{{ item.email }}</td>
                  <td class="text-nowrap text-muted">{{ item.phone_number }}</td>
                  <td class="text-nowrap text-muted">{{ item.created_at_str }}</td>
                  <td>{{ item.uploader_name }}</td>
                  <td>
                    <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
                  </td>
                  <td>{{ item.approver_name }}</td>
                  <td v-if="canApproveUser">
                    <el-button @click="handlerApprovalBtn('approved', item)" size="mini" round :disabled="item.status != 'waiting_approval'" type="success">
                      <font-awesome-icon icon="check"/>
                    </el-button>
                    <el-button @click="handlerApprovalBtn('rejected', item)" size="mini" round :disabled="item.status != 'waiting_approval'" type="danger">
                      <font-awesome-icon icon="times"/>
                    </el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <el-empty v-if="draft_opt.total_rows == 0" description="No data found" :image="emptyStateImage"></el-empty>
          <pagination
            :page="draft_opt.page"
            :per_page="draft_opt.per_page"
            :list="draft_opt.list"
            :total_rows="draft_opt.total_rows"
            @pageChange="pageChangeHandlerDraft"/>
        </b-tab>
      </b-tabs>
      <b-modal size="lg" v-model="showModalImportUser" title="Import Private User" hide-footer>
        <b-form @submit.prevent="confirmImportUser" @reset="closeModalImportUser">
          <b-form-group label="File">
            <b-form-file
              v-model="v$.formImportPrivate.file.$model"
              :state="Boolean(v$.formImportPrivate.file.$model)"
              class="border-1 p-2"
              accept=".csv, .xlsx, .xls"
              ref="fileInput"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <b-form-invalid-feedback :state="false">
              <div class="w-full flex flex-row justify-content-between">
                <span>*only .csv, .xlsx or .xls file allowed</span>
                <a :href="require('../../../assets/samples/Sample-Import-User.xlsx')" download><font-awesome-icon icon="download"/> Download example file</a>
              </div>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="!v$.formImportPrivate.file.$invalid" v-if="v$.formImportPrivate.file.$errors[0]">
              <span> {{ v$.formImportPrivate.file.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="confirmImportUser" :loading="loading.import_user" class="mr-2 ml-2" size="small" type="primary">Submit Import User</el-button>
            <el-button @click="closeModalImportUser" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
       <b-modal size="lg" v-model="showModalEwaTier" title="Import Ewa Tier" hide-footer>
        <b-form @submit.prevent="confirmEwaTier" @reset="closeModalEwaTier">
          <div v-if="temp_selected && temp_selected.length > 0">
          <b-form-group label="Select Ewa Tier">
            <el-select class="mb-2" v-model="form.packet" placeholder="Select or search packet ewa tier" style="width: 100%" filterable @change="handlePacketChange">
              <el-option  value="0" label="0"/>
              <el-option v-for="item in ewaTier" :key="item.packet" :label="item.packet" :value="item.packet"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="EWA Request Limit">
            <b-input v-model="ewaRequestLimit" type="text" readonly placeholder="-"/>
          </b-form-group>
          <b-form-group label="Admin Fee EWA">
            <b-input v-model="adminFeeEwa" type="text" readonly  placeholder="-"  />
          </b-form-group>
          <b-form-group label="Admin Fee Type">
            <b-input v-model="adminFeeType" type="text" readonly  placeholder="-"  />
          </b-form-group>
          </div>
          <div v-else>
            <b-form-group label="Information for Ewa Tier">
              <el-select class="mb-2" v-model="form.packet" placeholder="Select or search packet ewa tier" style="width: 100%" filterable @change="handlePacketChange">
                <el-option v-for="item in ewaTier" :key="item.packet" :label="item.packet" :value="item.packet"></el-option>
              </el-select>
            </b-form-group>
            <b-form-group label="EWA Request Limit">
              <b-input v-model="ewaRequestLimit" type="text" readonly placeholder="-"/>
            </b-form-group>
            <b-form-group label="Admin Fee EWA">
              <b-input v-model="adminFeeEwa" type="text" readonly  placeholder="-"  />
            </b-form-group>
            <b-form-group label="Admin Fee Type">
              <b-input v-model="adminFeeType" type="text" readonly  placeholder="-"  />
            </b-form-group>
            <b-form-group label="File">
              <b-form-file
                v-model="selected_file.csv"
                :state="Boolean(selected_file.csv)"
                class="mt-2 border-1 p-2"
                @input="onChangeFile($event, 'csv')"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <b-form-invalid-feedback :state="false">
                <div class="w-full flex flex-row justify-content-between">
                  <span>*only .csv file allowed</span>
                <a href="/sample/Sample-EWA-TIER.csv" download><font-awesome-icon icon="download"/> Download example file</a>   
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <b-form-group label="Total Selected Users" v-if="temp_selected && temp_selected.length > 0">
            <b-input v-model="temp_selected.length" type="text" readonly placeholder="-" />
            </b-form-group>
          <div class="float-right p-2">
            <el-button @click="confirmEwaTier" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="closeModalEwaTier" size="small" class="mr-2 ml-2">Cancel</el-button>
            <br>
          </div>
        </b-form>
      </b-modal>
      <b-modal size="lg" v-model="showModalRemoveUser" title="Remove Private User" hide-footer>
        <b-form @submit.prevent="confirmRemoveUser" @reset="closeModalRemoveUser">
          <b-form-group label="File">
            <b-form-file
              v-model="v$.formRemovePrivate.file.$model"
              :state="Boolean(v$.formRemovePrivate.file.$model)"
              class="border-1 p-2"
              accept=".csv, .xlsx, .xls"
              ref="fileInput"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <b-form-invalid-feedback :state="false">
              <div class="w-full flex flex-row justify-content-between">
                <span>*only .csv, .xlsx or .xls file allowed</span>
                <a :href="require('../../../assets/samples/Sample-Remove-User.xlsx')" download><font-awesome-icon icon="download"/> Download example file</a>
              </div>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="!v$.formRemovePrivate.file.$invalid" v-if="v$.formRemovePrivate.file.$errors[0]">
              <span> {{ v$.formRemovePrivate.file.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="confirmRemoveUser" :loading="loading.remove_user" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="closeModalRemoveUser" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
      <single-remove-private
          :is-shown="showModalSingleRemove"
          :selected-user="selected_user"
          @onHide="showModalSingleRemove = false; loadCompanyPrivateUser()" />
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  GET_COMPANIES_PRIVATE, GET_DRAFT_IMPORT_USER, APPROVAL_DRAFT_IMPORT_USER,
} from '@/store/modules/companies';
import {
  IMPORT_PRIVATE_USER, SINGLE_REMOVE_PRIVATE_USERS, 
} from '@/store/modules/app_user';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '../../../library/popup-error-messages';
import Pagination from '../../components/Pagination.vue';
import SingleRemovePrivate from '../app_user/SingleRemovePrivate.vue';
import base64File from '@/library/base64File';
import imageCompress from '@/library/vue-image-compression';
import { IMPORT_EWA_TIER, GET_COMPANIES } from '../../../store/modules/companies';

export default {
  components: { Pagination, SingleRemovePrivate },
  name: 'PrivateUserList',
  metaInfo: {
    title: 'Companies Private User',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      packetNames: "", 
      ewaRequestLimit: "", 
      adminFeeEwa: "",
      adminFeeType: "",
      packet: "",
      companies: {
        rows: [],
        count: 0,
      },
      csv: null,
      csv_base64: '',
      file_tmp: '',
      ewaTier: null,
      user_filter: {
        search_by: 'full_name',
        search_keyword: '',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      temp_selected: null,
      loading: {
        table: false,
        submit: false,
      },
      form: {
        packet_name: '',
        packet: '',
      },
      temp_checked: {},
      checked: {},
      // selectedRecipients: [],
      // selectedRecipients: ,
      selectedRecipients: null,
      selected_file: {
        csv: null,  
        csv_base64: null,  
    },
      emptyStateImage,
      loading: {
        list: false,
        import_user: false,
        remove_user: false,
      },
      page: 1,
      per_page: 10,
      search_by: 'email',
      search_keyword: '',
      showModalEwaTier: false,
      showModalImportUser: false,
      showModalRemoveUser: false,
      formImportPrivate: {
        company_id: '',
        file: null,
      },
      formRemovePrivate: {
        company_id: '',
        file: null,
      },
      list: [],
      total_rows: 0,
      search_options: [
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Name',
          value: 'full_name',
        },
        {
          text: 'Phone Number',
          value: 'phone_number',
        },
      ],
      draft_opt: {
        list: [],
        total_rows: 0,
        page: 1,
        per_page: 10,
        search: {
          search_by: 'email',
          search_keyword: '',
          status_filter: '',
        },
      },
      current_active_tab: 0,
      showModalSingleRemove: false,
      selected_user: {},
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    currentMyProfile() {
      return this.$store.getters.currentMyProfile;
    },
    canImportUser() {
      return (
        this.currentRole.page_name === '*' ||
        this.currentRole.page_name === 'companies' ||
        this.currentMyProfile.is_maker
      );
    },
    currentRole() {
      return this.$store.getters.current_role;
    },
    canApproveUser() {
      return (
        this.currentRole.page_name === '*' ||
        this.currentRole.page_name === 'companies' ||
        this.currentMyProfile.is_checker
      );
    },
    selectedRecipients() {
      return this.temp_selected;
    },
  },
  async mounted() {
    await this.loadCompanyPrivateUser();
    this.loadCompanies()
  },
  methods: {
    getEwaDetails(ewa_tier) {
      const selectedItem = this.ewaTier.find(item => item.packet === ewa_tier);
      // console.log(`Ewa details for ewa_tier ${ewa_tier}:`, selectedItem);
      return {
        admin_ewa: selectedItem ? selectedItem.admin_fee_ewa : '-',
        req_ewa_limit: selectedItem ? selectedItem.ewa_req_limit : '-'
      };
    },
    async onChangeFile(event, target) {
      try {
        if (this.selected_file[target]) {
          const compressed = await imageCompress(this.selected_file[target]);
          this.selected_file[`${target}_base64`] = await base64File(compressed);
          this.csv_base64 = this.selected_file[`${target}_base64`];
        } else {
          console.warn(`No file selected for target: ${target}`);
        }
      } catch (error) {
        console.error('Error during onChangeFile process:', error);
      }
    },
    loadEwaTierFromLocalStorage() {
      const ewaTierString = localStorage.getItem('ewa_tier');
      if (ewaTierString) {
        try {
          this.ewaTier = JSON.parse(ewaTierString);
          // console.log("ewaTier", this.ewaTier)
        } catch (e) {
          console.error('Failed to parse ewa_tier from localStorage:', e);
        }
      }
    },
    resetForm() {
      // this.ewaTier = [];
      this.packetNames = "";
      this.ewaRequestLimit = "";
      this.adminFeeEwa = "";
      this.adminFeeType = "";
      this.packet = "";
    },
    handlePacketChange(selectedPacketName) {
      const selectedPacket = this.ewaTier.find(
        (item) => item.packet === selectedPacketName
      );
      if (selectedPacket) {
        this.packet = selectedPacket.packet || "";
        this.ewaRequestLimit = selectedPacket.ewa_req_limit || "";
        this.adminFeeEwa = selectedPacket.admin_fee_ewa || "";
        this.adminFeeType = selectedPacket.admin_fee_type || "";
      } else {
        this.resetForm();
      }
    },
    onSubmit() {
      this.selectedRecipients = [...this.temp_selected];
      // this.selectedRecipients = this.temp_selected[0];
    },
    handlerChooseRecipient() {
      this.showModalRecipientTable = true;
    },
    handlerSelectionChange(val) {
      this.temp_selected = val.map(item => item.owned_by);
    },
    onHide() {
      this.$emit('onHide');
    },
    async loadCompanyPrivateUser() {
      this.$parent.showLoader();
      this.loading.list = true;
      await this.$store.dispatch(GET_COMPANIES_PRIVATE, {
        id: this.$route.params.id,
        query: {
          page: this.page,
          search_keyword: this.search_keyword,
          search_by: this.search_by,
        },
      });
      const { count, rows } = this.$store.getters.company_private_user;
      this.list = rows;
      this.total_rows = count;
      this.loading.list = false;
      this.$parent.hideLoader();
    },
    async pageChangeHandlerTest(page) {
      this.checked[`page${this.page}`] = this.temp_checked[`page${this.page}`] || [];
      this.page = page;
      // await this.getList();
      await this.loadCompanyPrivateUser();
      if (this.checked[`page${this.page}`]) {
        this.checked[`page${this.page}`].forEach((v) => {
          const obj = this.list.find((k) => k.owned_by === v.owned_by);
          if (obj) {
            // console.log('toggle', obj);
            setTimeout(() => {
              this.$refs.list_table.toggleRowSelection(obj);
            }, 100);
          }
        });
      }
    },
    pageChangeHandler(page) {
      this.page = page;
      this.loadCompanyPrivateUser();
    },
    pageChangeHandlerDraft(page) {
      this.draft_opt.page = page;
      this.getDraftList();
    },
    searchHandler() {
      this.page = 1;
      this.loadCompanyPrivateUser();
    },
    searchHandlerTest() {
      this.page = 1;
      this.search_by = this.user_filter.search_by;
      this.search_keyword = this.user_filter.search_keyword;
      this.getList();
    },
    searchHandlerDraft() {
      this.draft_opt.page = 1;
      this.getDraftList();
    },
    doShowModalImportUser() {
      this.showModalImportUser = true;
      this.formImportPrivate.company_id = this.$route.params.id;
      this.v$.formImportPrivate.$touch();
    },
    doShowModalEwaTier() {
      this.showModalEwaTier  = true;  
    },
    doShowModalRemoveUser() {
      this.showModalRemoveUser = true;
      this.formRemovePrivate.company_id = this.$route.params.id;
      this.v$.formRemovePrivate.$touch();
    },
    async confirmImportUser() {
      this.v$.formImportPrivate.$touch();
      if (this.v$.formImportPrivate.$error) return;
      const data = new FormData();
      data.append('file', this.formImportPrivate.file);
      this.loading.import_user = true;
      await this.$store.dispatch(IMPORT_PRIVATE_USER, {
        id: this.formImportPrivate.company_id,
        data,
      }).then(() => {
        this.formImportPrivate = {
          company_id: '',
          file: null,
        };
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Import private user completed.',
        });
        this.closeModalImportUser();
        this.loadCompanyPrivateUser();
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
      this.loading.import_user = false;
    },
    async confirmEwaTier() {
      const companyId = this.$route.params.id;
      // console.log("Company ID:", companyId)
      const data = new FormData();
      this.loading.import_user = true;
      let payload;
        if (this.form.packet === "0") {
          payload = {
            company_id: companyId,
            owned_by: this.temp_selected,
            ewa_tier: 0,
          };
        } else if (this.temp_selected) {
          payload = {
            company_id: companyId,
            owned_by: this.temp_selected,
            ewa_tier: this.packet
          };
        } else {
          payload = {
            company_id: companyId,
            csv_base64: this.csv_base64,
            ewa_tier: this.ewaTier,
          };
        }
      await this.$store.dispatch(IMPORT_EWA_TIER, payload)
        .then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Import Ewa Tier user completed.',
        });
        this.closeModalEwaTier();
        this.resetForm();
        this.showModalEwaTier = false,
        this.selectedRecipients = ''
        this.ewa_tier= '',
        this.company_id = '',
        this.loadCompanyPrivateUser();
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
      this.showModalEwaTier = false,
      this.loading.import_user = false;
    },
    async confirmRemoveUser() {
      this.v$.formRemovePrivate.$touch();
      if (this.v$.formRemovePrivate.$error) return;
      const data = new FormData();
      data.append('file', this.formRemovePrivate.file);
      data.append('company_id', this.formRemovePrivate.company_id);
      this.loading.remove_user = true;
      await this.$store.dispatch(SINGLE_REMOVE_PRIVATE_USERS, {
        id: this.formRemovePrivate.company_id,
        data,
      }).then(() => {
        this.formRemovePrivate = {
          company_id: '',
          file: null,
        };
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Remove private user completed.',
        });
        this.closeModalRemoveUser();
        this.loadCompanyPrivateUser();
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
      this.loading.remove_user = false;
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.companies = this.$store.getters.companies;
      const companyId = this.$route.params.id;
        if (this.companies && Array.isArray(this.companies.rows)) {
        const company = this.companies.rows.find(company => company.company_id === companyId);
        if (company) {
        } else {
        }
      } else {
      }
    },
    closeModalImportUser() {
      this.showModalImportUser = false;
      this.loading.import_user = false;
    },
    closeModalEwaTier() {
      this.showModalEwaTier = false;
      this.loading.import_user = false;
      this.selectedRecipients = '';
      this.packetNames = '', 
      this.ewaRequestLimit = '', 
      this.adminFeeEwa = '',
      this.adminFeeType = '';
      this.packet = '';
      this.form.packet = ''
      this.temp_selected = null;
      this.$refs.list_table.clearSelection();
    },
    closeModalRemoveUser() {
      this.showModalRemoveUser = false;
      this.loading.remove_user = false;
    },
    async getDraftList() {
      this.$parent.showLoader();
      await this.$store.dispatch(GET_DRAFT_IMPORT_USER, {
        id: this.$route.params.id,
        query: {
          page: this.draft_opt.page,
          ...this.draft_opt.search,
        },
      });
      const { count, rows } = this.$store.getters.draft_import_user;
      this.draft_opt.list = rows;
      this.draft_opt.total_rows = count;
      this.$parent.hideLoader();
    },
    handlerTabChange(value) {
      if (value === 0) {
        this.loadCompanyPrivateUser();
      } else if (value === 1) {
        this.getDraftList();
      }
    },
    handlerApprovalBtn(action, item) {
      // APPROVAL_DRAFT_IMPORT_USER
      this.$confirm(`Are you sure want to ${action === 'approved' ? 'approve' : 'reject'} this private user?`, 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action1, instance, done) => {
          if (action1 === 'confirm') {
            // this.doCreatePromo();
            instance.confirmButtonLoading = true;
            try {
              await this.$store.dispatch(APPROVAL_DRAFT_IMPORT_USER, {
                id: item.company_id,
                data: {
                  action,
                  req_id: item.id,
                },
              }).then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Approval success',
                });
                this.getDraftList();
                done();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
              instance.confirmButtonLoading = false;
            } catch (err) {
              console.log(err);
            }
            instance.confirmButtonLoading = false;
          }
          done();
        },
      });
    },
    handlerBtnSingleRemove(item) {
      this.selected_user = item;
      this.showModalSingleRemove = true;
    },
    doDownload() {
      const csvContent = this.convertToCSV(this.list);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    convertToCSV(data) {
      const fields = ['full_name', 'email', 'phone_number', 'ewa_tier', 'created_at', 'login_at', 'user_status'];
      const header = fields.join(",") + "\n";
      const rows = data.map(row => {
        return fields.map(field => {
          if (field === 'user_status') {
            return row[field] === 1 ? 'Active' : row[field] === 0 ? 'Inactive' : '';
          }
          return row[field] || '';
        }).join(",");
      }).join("\n");
      return header + rows;
    }
  },
  created() {
   this.loadEwaTierFromLocalStorage();
  },
  validations() {
    return {
      formImportPrivate: {
        company_id: { required },
        file: { required },
      },
      formRemovePrivate: {
        company_id: { required },
        file: { required },
      },
    };
  },
};
</script>